<div (window:scroll)="windowScroll()" id="home">
  <header class="navbar d-block navbar-sticky navbar-expand-lg navbar-light bg-light header-color">
    <div class="container"><a class="navbar-brand d-none d-sm-block me-4 order-lg-1" routerLink="/"><img src="assets/img/logo-dark.png" width="50" alt=""></a><a class="navbar-brand d-sm-none me-2 order-lg-1"><img src="assets/img/logo-icon.png" width="50" alt="Casa del Café"></a>
      <div class="navbar-toolbar d-flex align-items-center order-lg-3">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"><span class="navbar-toggler-icon" (click)="collapse.toggle()"></span></button><a class="navbar-tool d-none d-lg-flex" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#searchBox" role="button" aria-expanded="false" aria-controls="searchBox"><span class="navbar-tool-tooltip">{{"TRANSLATE.SEARCH" | translate}}</span>
          <div class="navbar-tool-icon-box" (click)="collapse.toggle()"><i class="navbar-tool-icon ci-search font-color"></i></div>
        </a>
        <div ngbDropdown class="btn-group dropdown disable-autohide">
          <button class="btn text-language btn-outline-light border-light btn-sm dropdown-toggle px-2" type="button" data-bs-toggle="dropdown" ngbDropdownToggle>
            <img class="me-2" src="{{flagvalue}}" width="20" alt="">{{selectedLanguage}}
          </button>
          <ul ngbDropdownMenu class="dropdown-menu my-1">
            @for(item of listLang;track $index){
            <li (click)="setLanguage(item.text, item.lang, item.flag,item.language)">
              <a class="dropdown-item pb-1" [ngClass]="{'active': cookieValue === item.lang}" href="javascript:void(0);">
                <img class="me-2" src="{{item.flag}}" width="20" alt="{{item.text}}">{{item.text}}
              </a>
            </li>
          }
          </ul>
        </div>
        @if(this._user){
          <div class="btn-group dropdown" ngbDropdown>
            <a mat-button class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <i class="navbar-tool-icon ci-user text-primary"></i>
            </a>
            <div class="dropdown-menu my-1" ngbDropdownMenu >
              <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/informacion">
                <i class="ci-user opacity-60 me-2"></i>{{'ACCOUNT.ACCOUNT' | translate}}
              </a>
              <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/comentario">
                <i class="ci-book opacity-60 me-2"></i>{{'ACCOUNT.SEND_FEEDBACK' | translate}}
              </a>
              <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/tarjetas">
                <i class="ci-card opacity-60 me-2"></i>{{'ACCOUNT.PAYMENT_METHODS' | translate}}
              </a>
              <a href="javascript:void(0);" class="dropdown-item" routerLink="/cuenta/pedidos">
                <i class="ci-bag opacity-60 me-2"></i>{{'ACCOUNT.MY_ORDERS' | translate}}
              </a>
              <div class="dropdown-divider"></div>
              <a href="javascript:void(0);" class="dropdown-item" (click)="logout()">
                <i class="ci-sign-out opacity-60 me-2"></i>{{'TRANSLATE.SIGN_OUT' | translate}}
              </a>
            </div>
          </div>
        }@else {
          <a class="navbar-tool ms-2" href="javascript:void(0);" [routerLink]="['/auth/ingresar']"><span class="navbar-tool-tooltip">{{"ACCOUNT.ACCOUNT" | translate}}</span>
            <div class="navbar-tool-icon-box"><i class="navbar-tool-icon ci-user"></i></div>
          </a>
        }
        <div class="navbar-tool dropdown ms-3 cart">
          <a class="navbar-tool-icon-box bg-secondary dropdown-toggle" routerLink="/cart">
            <span class="navbar-tool-label">{{ecommerceService.Data.totalCartCount}}</span><i class="navbar-tool-icon ci-cart"></i>
          </a>
          <!-- Cart dropdown-->
          <div class="dropdown-menu dropdown-menu-end">
            <div class="widget widget-cart px-3 pt-2 pb-3" style="width: 20rem;">
              <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false">
                @for(cart of ecommerceService.Data.cartList;track $index){
                <ng-container *ngIf="cart.material_json as material">
                  <div class="widget-cart-item pb-2 border-bottom">
                    <!-- <button class="btn-close text-danger" type="button" aria-label="Remove" (click)="removecart(cart.id)"><span aria-hidden="true">&times;</span></button> -->
                    <div class="d-flex align-items-center"><a class="d-block" href="javascript:void(0);">
                      <img [src]="(material.images?.length > 0) ? ecommerceService.getPathImageSmall(material.images[0].fileName) : 'assets/images/others/not-found.png'" 
                      alt="{{material.images?.[0]?.fieldName}}" onerror="this.src='assets/images/others/not-found.png';" width="64">
                    </a>
                      <div class="ps-2">
                        <h6 class="widget-product-title"><a href="javascript:void(0);">{{material.name}}</a></h6>
                        <div class="widget-product-meta"><span class="text-accent me-2">${{material.newPrice}}</span><span class="text-muted">x {{material.cartCount}}</span></div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              }
              </div>
              <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                <div class="fs-sm me-2 py-2">
                  <span class="text-muted">Total:</span><span class="text-accent fs-base ms-1">${{calculatetotal(total)}}</span>
                </div><a class="btn btn-outline-secondary btn-sm" href="javascript:void(0);" [routerLink]="['/cart']">Ver carrito<i class="ci-arrow-right ms-1 me-n1"></i></a>
              </div><a class="btn btn-primary btn-sm d-block w-100" href="javascript:void(0);" routerLink="/checkout"><i class="ci-card me-2 fs-base align-middle"></i>Checkout</a>
            </div>
          </div>
        </div>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapse navbar-collapse me-auto order-lg-2" id="navbarCollapse">
        <!-- Search (mobile)-->
        <div class="d-lg-none py-3">
          <div class="input-group"><i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input class="form-control rounded-start" type="text" [(ngModel)]="term" [placeholder]="'TRANSLATE.WHAT_DO_U_NEED' | translate">
          </div>
        </div>
        <!-- Location dropdown-->
        <ul class="navbar-nav navbar-mega-nav ms-lg-4 pe-lg-2 me-lg-2">
          <li ngbDropdown class="nav-item dropdown"><a class="nav-link font-color dropdown-toggle ps-lg-0" href="javascript:void(0);" data-bs-toggle="dropdown" ngbDropdownToggle>{{'MENUITEMS.ORDER_NOW' | translate}}</a>
            <div class="dropdown-menu px-2 ps-0 pb-4" ngbDropdownMenu>
              <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                <div class="mega-dropdown-column pt-4 px-3 align-items-center " style="width: 100%; max-width: 13rem;">
                  <div class="widget mb-3">
                      <a class="d-block" href="javascript:void(0);" (click)="setSubOrder('PR')">
                      <mat-icon class="d-block mx-auto mb-3 mat-icon-xlg">restaurant</mat-icon>
                      <h6 class="fs-base text-center">{{ "Consumir en el restaurante" | translate }}</h6>
                    </a>
                  </div>
                </div>
                <div class="mega-dropdown-column pt-4 px-3 align-items-center " style="width: 100%; max-width: 13rem;">
                  <div class="widget mb-3">
                    <a class="d-block" href="javascript:void(0);" (click)="setSubOrder('RP')">
                      <mat-icon class="d-block mx-auto mb-3 mat-icon-xlg">store</mat-icon>
                      <h6 class="fs-base text-center">{{ "Para llevar" | translate }}</h6>
                    </a>
                  </div>
                </div>
                <div class="mega-dropdown-column pt-4 px-3 align-items-center " style="width: 100%; max-width: 13rem;">
                  <div class="widget mb-3">
                    <a class="d-block" href="javascript:void(0);" (click)="setSubOrder('RC')">
                      <mat-icon class="d-block mx-auto mb-3 mat-icon-xlg">emoji_transportation_outlined</mat-icon>
                      <h6 class="fs-base text-center">{{ "Recoge en autoservicio" | translate }}</h6>
                    </a>
                  </div>
                </div>
              </div>              
            </div>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/nosotros">{{ "MENUITEMS.ABOUT" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/carreras">{{ "MENUITEMS.CAREER" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/noticia">{{ "MENUITEMS.NEWS" | translate }}</a></li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link font-color" href="javascript:void(0);" routerLink="/cdc-rewards">{{ "MENUITEMS.REWARDSCDC" | translate }}</a></li>
        </ul>
      </div>
    </div>
    <!-- Search collapse-->
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="search-box collapse" id="searchBox">
      <div class="container py-2">
        <div class="input-group"><i class="ci-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
          <input class="form-control rounded-start" type="text"  [placeholder]="'TRANSLATE.WHAT_DO_U_NEED' | translate">
        </div>
      </div>
    </div>
  </header>
</div>

<div class="handheld-toolbar">
  <div class="d-table table-layout-fixed w-100">
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" [routerLink]="this._user ? ['/cuenta/informacion'] : ['/auth/ingresar']">
      <span class="handheld-toolbar-icon"><i class="ci-user"></i></span>
      <span class="handheld-toolbar-label">{{"ACCOUNT.ACCOUNT" | translate}}</span>
    </a>
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" (click)="collapse.toggle()" onclick="window.scrollTo(0, 0)">
      <span class="handheld-toolbar-icon"><i class="ci-menu"></i></span>
      <span class="handheld-toolbar-label">Menu</span>
    </a>
    <a class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" routerLink="/cart">
      <span class="handheld-toolbar-icon"><i class="ci-cart"></i>
        <span class="badge bg-primary rounded-pill ms-1">{{carts.length}}</span>
      </span>
      <span class="handheld-toolbar-label">${{calculatetotal(total)}}</span>
    </a>
  </div>
</div>
