import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { cartdata } from 'src/app/pages/cart/data';
import { ModeOrder, Store } from 'src/app/core/models/mode-order.model';
import { ModeOrderService } from 'src/app/services/ecommerce/mode-order.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';
import { LanguageService } from 'src/app/services/global/language.service';
import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from 'src/app/services/global/company.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isCollapsed = true;
  carts: any;
  total: any = 0;
  term: any;
  cartList: any[] = []
  //Languages
  selectedLanguage: any;
  flag: any;
  countryName: any;
  cookieValue: any;
  flagvalue: any;
  valueset: any;

  obs: Subscription[] = []
  _user: any = null

  mode: ModeOrder = new ModeOrder();
  selected = null
  subSelected = null

  constructor(
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    public router: Router,
    private authService: AuthenticationService,
    private orderModeService: ModeOrderService,
    public ecommerceService: EcommerceService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private companyService: CompanyService,
  ) {
    translate.setDefaultLang('es');

    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this._user = res == null ? null : res.user
      })
    )

    this.obs.push(
      this.orderModeService.currentMode.subscribe((res: any) => {
        this.mode = res
        /* if (res == null) {
          this.selected = null
          let object = new ModeOrder()
          object.orderSelected = 'PD'
          
          this.orderModeService.updateCurrentModeOrder(object)
        } else {
          this.selected = this.mode.orderSelected
          if (this.mode.orderSelected == null) {
            let object = new ModeOrder()
            object.orderSelected = 'PD'
            this.orderModeService.updateCurrentModeOrder(object)
          }
        }
        if (res == null) {
          this.subSelected = null
        } else {
          this.subSelected = this.mode.subOrderSelected
        } */
      })
    )
  }

  ngOnInit(): void {
    this.carts = cartdata

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.flagvalue = 'assets/img/flags/es.png'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.ecommerceService.getCart().subscribe(res => {
      this.cartList = res || [];
      console.log('Carrito cargado ', this.cartList);
    });

    this.selectedLanguage = 'Es / $'
  }

  // calculate cart total
  calculatetotal(total: any) {
    this.total = 0
    this.carts.forEach((element: any) => {
      this.total += parseFloat(element.price)
    });
    return this.total.toFixed(2)
  }

  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.querySelector('.navbar-sticky');
    if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
      navbar?.classList.add('navbar-stuck');
      document.querySelector(".btn-scroll-top")?.classList.add('show');
    }
    else {
      navbar?.classList.remove('navbar-stuck');
      document.querySelector(".btn-scroll-top")?.classList.remove('show');
    }
  }

  // remove from cart
  removecart(materialId: number) {
    this.ecommerceService.removeCart(materialId).subscribe({
      next: (response) => {
        this.cartList = this.cartList.filter(product => product.id !== materialId);
        this.ecommerceService.Data.cartList = this.cartList;
        this.ecommerceService.updateTotalAndCount();
        this.ecommerceService.setStorageData();
      },
      error: (error) => {
        console.error('Error al eliminar el producto:', error);
      }
    });
  }

  //Mode order
  setSubOrder(data: any) {
    if (this.mode == null) {
      this.mode = new ModeOrder()
    }
    //TODO this.ecommerce.resetCart()
    this.mode.orderSelected = 'pickup'
    this.mode.subOrderSelected = data
    this.mode.storeSelected = new Store()
    this.orderModeService.updateCurrentModeOrder(this.mode)

    this.router.navigate(["/ubicacion-restaurantes"])
  }

  logout() {
    this.authService.logout()
  }

  /***
* Language Listing
*/
  listLang = [
    { text: 'Español', flag: 'assets/img/flags/es.png', lang: 'es', language: 'Es / $' },
    { text: 'English', flag: 'assets/img/flags/en.png', lang: 'en', language: 'Eng / $' }
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string, language: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.selectedLanguage = language
    this.languageService.setLanguage(lang);
  }

  ngOnDestroy() {
    this.obs.forEach(element => {
      element.unsubscribe()
    });
  }
}
