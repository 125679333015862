export class City {
    code!: string
    name!: string
}
export class Address {
    name: string = ''
}
export class Store {
    id: Number = 0
    address: string = ''
    name: string = ''
    username: string = ''
    location_two_code: string = ''
    location_two_name: string = ''
    general_range_hour: any[] = [] //TO DO VER TIPO DE DATO
    office_code: any
}

//TO DO VER TIPO DE DATO
export class ModeOrder {
    orderSelected: any = null // por defecto
    subOrderSelected: any = null
    storeSelected: Store = new Store()
    citySelected: City = new City()
    addressSelected: Address = new Address()
}