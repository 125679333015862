<div class="mb-3 d-flex align-items-center justify-content-center">
  <button mat-icon-button class="btn-circle btn btn-primary" (click)="minus()" [disabled]="quantity <= 1">
    <mat-icon>remove</mat-icon>
  </button>
  <span class="mx-2">{{ quantity }}</span>
  <button mat-icon-button class="btn-circle btn btn-primary me-3" (click)="plus()">
    <mat-icon>add</mat-icon>
  </button>
  <button class="btn btn-primary btn-shadow d-block w-100" type="submit" (click)="addToCart(product)">
    <i class="ci-cart fs-lg me-2"></i>Ordenar
  </button>
</div>